import { useState, useEffect, useRef, Fragment } from "react"
import axiosGet from "../../../helpers/axiosGet"
import Loading from "../../Loading/Loading"
import { setPalisadeForm, resetList } from "../../../store/list/actions"
import { useDispatch, useSelector } from "react-redux"
import Pagination from "../../Pagination/Pagination"
import TableData from "../../TableData/TableData"
import TableForm from "../../TableForm/TableForm"
import { useCallback } from "react"

export default function PalisadeList() {
  const dispatch = useDispatch()
  const scroll = useRef(null)
  const handleScroll = () => {
    scroll.current.scrollIntoView({
      behavior: "smooth",
    })
  }

  const palisadeForm = useSelector((state) => state.list.palisadeForm)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [totalPage, setTotalPage] = useState(1)

  const getLists = useCallback(() => {
    setLoading(true)

    axiosGet({
      url: `/page/${palisadeForm.storedPage}?certificateNumber=${palisadeForm.storedValues.certificateNumber}&placingSlipNumber=&insured=${palisadeForm.storedValues.insured}&minApplicationDate=${palisadeForm.storedValues.minApplicationDate}&maxApplicationDate=${palisadeForm.storedValues.maxApplicationDate}&status=&uploadId&maxCount=`,
      callback: (res) => {
        setLoading(false)
        setRows(res.data.data)
        setTotalPage(res.data.totalPage)
      },
      errorCallback: (err) => {
        setLoading(false)
        setError(true)
        setErrorMessage(err.message)
      },
    })
  }, [
    palisadeForm.storedPage,
    palisadeForm.storedValues.certificateNumber,
    palisadeForm.storedValues.insured,
    palisadeForm.storedValues.maxApplicationDate,
    palisadeForm.storedValues.minApplicationDate,
  ])

  useEffect(() => {
    getLists()

    return () => {
      dispatch(resetList)
    }
  }, [dispatch, getLists])

  const handlePage = (page) => {
    handleScroll()
    dispatch(
      setPalisadeForm({
        ...palisadeForm,
        storedPage: page.selected + 1,
      })
    )
  }

  const handleSearch = (data) => {
    handleScroll()
    dispatch(
      setPalisadeForm({
        storedValues: data,
        storedPage: 1,
      })
    )
  }

  const handleReset = () => {
    handleScroll()
    dispatch(
      setPalisadeForm({
        storedValues: {
          certificateNumber: "",
          insured: "",
          minApplicationDate: "",
          maxApplicationDate: "",
        },
        storedPage: 1,
      })
    )
  }

  const forms = [
    {
      label: "Certificate Number",
      name: "certificateNumber",
      type: "text",
    },
    {
      label: "Insured",
      name: "insured",
      type: "text",
    },
    {
      label: "App Date From",
      name: "minApplicationDate",
      type: "date",
    },
    {
      label: "App Date To",
      name: "maxApplicationDate",
      type: "date",
    },
  ]

  return (
    <div className="palisade-list">
      <div className="border rounded bg-white" ref={scroll}>
        <TableForm
          title="palisade"
          forms={forms}
          defVals={palisadeForm.storedValues}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
      </div>

      <div className="mt-5">
        {loading ? (
          <Loading />
        ) : error ? (
          <div className="text-center red-text font-weight-bolder">
            {errorMessage}
          </div>
        ) : rows.length ? (
          <Fragment>
            <div className="border rounded bg-white">
              <TableData rows={rows} title="palisade" />
            </div>

            {totalPage > 1 && (
              <Pagination
                totalPage={totalPage}
                page={palisadeForm.storedPage - 1}
                handlePage={handlePage}
              />
            )}
          </Fragment>
        ) : (
          <div className="text-center font-weight-bold">NO DATA</div>
        )}
      </div>
    </div>
  )
}
