import { Fragment } from "react";
import { MDBIcon } from "mdbreact";
import "./Button.css";

export default function Button({
  loading,
  text,
  type,
  icon,
  onClick,
  secondary,
  small,
  back,
  circle,
  disabled,
  full,
}) {
  const classes = (secondary, small, circle, full) => {
    const rootClass = "rootButton";
    const secondaryClass = "secondary";
    const smallClass = "small";
    const circleClass = "circle";
    const fullClass = "full";

    if (circle) {
      return `${rootClass} ${circleClass}`;
    } else {
      if (full) {
        return `${rootClass} ${fullClass}`;
      } else {
        if (small) {
          return secondary
            ? `${rootClass} ${secondaryClass} ${smallClass}`
            : `${rootClass} ${smallClass}`;
        } else {
          return secondary ? `${rootClass} ${secondaryClass}` : rootClass;
        }
      }
    }
  };

  return (
    <button
      disabled={disabled}
      type={type}
      className={classes(secondary, small, circle, full)}
      onClick={onClick}
    >
      {loading ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : circle ? (
        <MDBIcon icon={icon} />
      ) : (
        <Fragment>
          {back && <MDBIcon icon="arrow-left" className="mr-2"></MDBIcon>}
          <span>{text}</span>
          {!back && icon && <MDBIcon icon={icon} className="ml-2"></MDBIcon>}
        </Fragment>
      )}
    </button>
  );
}
