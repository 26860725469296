import React from "react"

export default function Loading() {
  const styles = {
    height: "85vh",
    width: 75,
  }

  return (
    <div
      className="mx-auto d-flex align-items-top justify-content-between"
      style={styles}
    >
      <div className="spinner-grow spinner-grow-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow spinner-grow-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow spinner-grow-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}
