import { Fragment } from "react"
import "./App.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { MDBContainer } from "mdbreact"
import Bg from "./img/triangle.svg"
import Navbar from "./components/Navbar/Navbar"
import Footer from "./components/Footer/Footer"
import Home from "./pages/Home/Home"
import Summary from "./pages/Summary/Summary"
import Login from "./pages/Login/Login"
import Password from "./pages/Password/Password"
import PalisadeDetail from "./pages/PalisadeDetail/PalisadeDetail"
import NotFound from "./pages/NotFound/NotFound"
import Heading from "./components/Heading/Heading"
import Notification from "./components/Notification/Notification"
import { useSelector } from "react-redux"
import ExcelDetail from "./pages/ExcelDetail/ExcelDetail"
import { Redirect } from "react-router-dom"

function App() {
  const isLogin = useSelector((state) => state.user.isLogin)
  const { isFileUploaded, isPasswordChanged } = useSelector(
    (state) => state.notif
  )
  const rows = useSelector((state) => state.file.rows)

  return (
    <Router>
      <Navbar />

      <img src={Bg} alt="triangle" className="triangle" />

      <MDBContainer>
        <main>
          <Switch>
            <Route exact path="/">
              <Heading />

              {isLogin ? (
                <Fragment>
                  <Home />

                  {isFileUploaded && (
                    <Notification title="file" message="File submitted." />
                  )}
                  {isPasswordChanged && (
                    <Notification
                      title="password"
                      message="Password changed."
                    />
                  )}
                </Fragment>
              ) : (
                <Login />
              )}
            </Route>

            <Route path="/summary">
              {isLogin ? (
                rows.length ? (
                  <Fragment>
                    <Heading />
                    <Summary />
                  </Fragment>
                ) : (
                  <NotFound />
                )
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route path="/password">
              {isLogin ? (
                <Fragment>
                  <Heading />
                  <Password />
                </Fragment>
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route path="/palisade/:id">
              {isLogin ? (
                <Fragment>
                  <Heading />
                  <PalisadeDetail />
                </Fragment>
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route path="/excel/:id">
              {isLogin ? (
                <Fragment>
                  <Heading />
                  <ExcelDetail />
                </Fragment>
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route>
              <NotFound />
            </Route>
          </Switch>
        </main>
      </MDBContainer>

      <Footer />
    </Router>
  )
}

export default App
