import { Fragment, useState } from "react"
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from "mdbreact"
import Button from "../Button/Button"
import { useForm } from "react-hook-form"

export default function TableForm({
  title,
  forms,
  handleSearch,
  defVals,
  handleReset,
}) {
  const { register, handleSubmit, reset } = useForm({ defaultValues: defVals })

  const onSubmit = (data) => handleSearch(data)

  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <div
        className="px-4 py-2"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}
      >
        <MDBRow between>
          <MDBCol>
            <p className="mb-0">Search</p>
          </MDBCol>
          <MDBCol middle>
            <div className="text-right">
              <MDBIcon icon={open ? "angle-up" : "angle-down"} size="lg" />
            </div>
          </MDBCol>
        </MDBRow>
      </div>

      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <MDBContainer className="border-top px-5 py-4 grey-text">
            <MDBRow className="create-small-gap">
              {forms.map((form, i) => (
                <MDBCol md={title === "excel" ? "4" : "6"} key={`forms${i}`}>
                  <label>{form.label}</label>
                  <input
                    type={form.type}
                    placeholder={form.label}
                    className="form-control"
                    {...register(form.name)}
                  />
                </MDBCol>
              ))}

              <MDBCol md="12">
                <MDBContainer>
                  <MDBRow end>
                    <Button
                      text="RESET"
                      type="button"
                      small
                      secondary
                      onClick={() => {
                        reset({ keepDefaultValues: true })
                        handleReset()
                      }}
                    />
                    <div className="ml-2">
                      <Button text="SEARCH" icon="search" type="submit" small />
                    </div>
                  </MDBRow>
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </form>
      )}
    </Fragment>
  )
}
