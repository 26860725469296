import { MDBCol, MDBIcon, MDBRow } from "mdbreact"
import { useState } from "react"
import axiosGet from "../../helpers/axiosGet"
import "./Download.css"
import { saveAs } from "file-saver"

export default function Download({ title, text, id, fileName }) {
  const [loading, setLoading] = useState(false)

  const handleDownload = () => {
    setLoading(true)

    axiosGet({
      url:
        title === "excel"
          ? `/upload/id/${id}/download-file/excel`
          : `/id/${id}/download-file/certificate`,
      responseType: "blob",
      callback: () => {
        setLoading(false)
      },
      errorCallback: (res) => {
        setLoading(false)
        let file = new File([res], fileName)
        saveAs(file)
      },
    })
  }
  return (
    <MDBRow end>
      <MDBCol md="6" xl="5">
        <div className="download-area z-depth-1">
          <div className="bg"></div>
          <MDBRow onClick={handleDownload} style={{ cursor: "pointer" }}>
            <MDBCol size="3" middle>
              <div style={{ color: "#006881" }}>
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <MDBIcon icon="cloud-download-alt" size="2x" />
                )}
              </div>
            </MDBCol>
            <MDBCol size="9" middle>
              <p className="font-weight-bold mb-0">{text}</p>
              <p className="mb-0">{fileName}</p>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBCol>
    </MDBRow>
  )
}
