import React, { useState } from "react"
import UserForm from "../../components/UserForm/UserForm"
import axiosPost from "../../helpers/axiosPost"
import { useDispatch, useSelector } from "react-redux"
import { setIsPasswordChanged } from "../../store/notif/actions"
import { useHistory } from "react-router-dom"
import constants from "../../constants"

export default function Password() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)

  const values = {
    username: user.username,
    oldPassword: "",
    newPassword: "",
    confNewPassword: "",
  }

  const inputs = [
    {
      name: "username",
      type: "text",
      aria: "Username",
      placeholder: "Username",
      icon: "user",
      disabled: true,
    },
    {
      name: "oldPassword",
      type: "password",
      aria: "Old Password",
      placeholder: "Old Password",
      icon: "lock",
      disabled: false,
    },
    {
      name: "newPassword",
      type: "password",
      aria: "New Password",
      placeholder: "New Password",
      icon: "lock",
      disabled: false,
    },
    {
      name: "confNewPassword",
      type: "password",
      aria: "Confirm New Password",
      placeholder: "Confirm New Password",
      icon: "lock",
      disabled: false,
    },
  ]

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const handleSubmit = (data) => {
    setLoading(true)
    setError(false)

    const params = {
      userId: user.id,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      confNewPassword: data.confNewPassword,
    }

    axiosPost({
      url: `${constants.AUTH_URL}/auth-user/change-password`,
      data: params,
      callback: () => {
        setLoading(false)
        dispatch(setIsPasswordChanged(true))
        history.push("/")
        window.scrollTo(0, 0)
      },
      errorCallback: (err) => {
        setLoading(false)
        setError(true)
        setErrorMessage(err.message)
        console.log("err", err)
      },
    })
  }

  return (
    <section className="change-password-page">
      <UserForm
        title="Change Password"
        loading={loading}
        inputs={inputs}
        values={values}
        submit={handleSubmit}
        buttonText="Change"
        error={error}
        errorMessage={errorMessage}
      />
    </section>
  )
}
