const initialState = {
  rows: [],
  file: null,
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILE":
      return {
        ...state,
        file: action.payload,
      };
    case "SET_ROWS":
      return {
        ...state,
        rows: action.payload,
      };
    case "RESET_FILE":
      return (state = initialState);
    default:
      return state;
  }
};

export default fileReducer;
