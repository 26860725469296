import * as dotenv from "dotenv";

dotenv.config({
  path: process.env.ENV_FILE,
  encoding: "utf8",
});

const constants = {};

constants.DEFAULT_URL =
  process.env.REACT_APP_DEFAULT_URL || "https://beta-api-palisade.polismall.id";
constants.AUTH_URL =
  process.env.REACT_APP_AUTH_URL || "https://beta-api-bo.polismall.id";

constants.HTTP_STATUS_SUCCESS = 200;
constants.HTTP_STATUS_UNAUTHORIZED = 403;

export default constants;
