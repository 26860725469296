import { useHistory } from "react-router-dom"
import { MDBTable, MDBTableHead, MDBTableBody, MDBIcon } from "mdbreact"
import moment from "moment"
import CurrencyFormat from "../CurrencyFormat"
import EstDepartureDate from "../EstDepartureDate"
import "./TableData.css"
import { Fragment } from "react"

export default function TableData({ rows, title }) {
  return (
    <MDBTable hover responsive>
      <MDBTableHead>
        <TableHead title={title} />
      </MDBTableHead>
      <MDBTableBody>
        {rows.map((row) => (
          <tr key={row.id}>
            <TableBody row={row} title={title} />
          </tr>
        ))}
      </MDBTableBody>
    </MDBTable>
  )
}

function TableHead({ title }) {
  return (
    <tr>
      {title === "excel" ? (
        <Fragment>
          <th>File Name</th>
          <th>Upload Date</th>
        </Fragment>
      ) : (
        <Fragment>
          <th style={{ minWidth: 170 }}>
            <div>Certificate Number</div>
            <div>Placing Slip Number</div>
            <div>Application Date</div>
          </th>
          <th style={{ minWidth: 150 }}>
            <div>Insured</div>
            <div>Insurer</div>
          </th>
          <th style={{ minWidth: 230 }}>
            <div>Route</div>
            <div>Est Departure Date</div>
            <div>Vessel</div>
          </th>
          <th style={{ minWidth: 130 }}>
            <div>TSI</div>
            <div>Net Premium</div>
          </th>
        </Fragment>
      )}

      <th>Action</th>
    </tr>
  )
}

function TableBody({ row, title }) {
  return (
    <Fragment>
      {title === "excel" ? (
        <Fragment>
          <td>{row.fileOriginalName}</td>
          <td>{moment(row.createdDate).format("DD/MM/YYYY")}</td>
        </Fragment>
      ) : (
        <Fragment>
          <td>
            <div>{row.certificateNumber}</div>
            <div>{row.placingSlipNumber}</div>
            <div>{moment(row.applicationDate).format("DD/MM/YYYY")}</div>
          </td>
          <td>
            <div>{row.insured} </div>
            <div>{row.insurer ? row.insurer : "-"} </div>
          </td>
          <td>
            <div>{row.route}</div>
            <div>
              <EstDepartureDate date={row.estDateDeparture} />
            </div>
            <div>{row.vessel}</div>
          </td>
          <td>
            <div>
              {row.currencyCode} {CurrencyFormat(row.sumInsured)}
            </div>
            <div>
              {row.currencyCode} {CurrencyFormat(row.netPremium)}
            </div>
          </td>
        </Fragment>
      )}

      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Action title={title} id={row.id} />
        </div>
      </td>
    </Fragment>
  )
}

function Action({ title, id }) {
  const history = useHistory()

  const handleAction = () => {
    const encode = window.btoa(id)

    history.push(title === "excel" ? `/excel/${encode}` : `/palisade/${encode}`)

    window.scrollTo(0, 0)
  }

  return (
    <div className="action" onClick={handleAction}>
      <MDBIcon icon="chevron-right" size="lg" className="white-text" />
    </div>
  )
}
