export const setIsFileUploaded = (payload) => {
  return {
    type: "SET_IS_FILE_UPLOADED",
    payload,
  };
};

export const setIsPasswordChanged = (payload) => {
  return {
    type: "SET_IS_PASSWORD_CHANGED",
    payload,
  };
};
