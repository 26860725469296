export const setActive = (payload) => {
  return {
    type: "SET_ACTIVE",
    payload,
  };
};

export const setPalisadeForm = (payload) => {
  return {
    type: "SET_PALISADE_FORM",
    payload,
  };
};

export const setExcelForm = (payload) => {
  return {
    type: "SET_EXCEL_FORM",
    payload,
  };
};

export const setExcelDetailForm = (payload) => {
  return {
    type: "SET_EXCEL_DETAIL_FORM",
    payload,
  };
};

export const resetList = (payload) => {
  return {
    type: "RESET_LIST",
    payload,
  };
};
