import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { MDBNavbar, MDBNavbarBrand, MDBIcon } from "mdbreact";
import Logo from "../../img/polismall.png";
import "./Navbar.css";
import { useSelector, useDispatch } from "react-redux";
import { resetUser } from "../../store/user/actions";
import { resetFile } from "../../store/file/actions";
import { resetList } from "../../store/list/actions";

export default function Navbar() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLogin, user } = useSelector((state) => state.user);

  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handlePassword = () => {
    history.push("/password");
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    dispatch(resetUser(true));
    dispatch(resetFile(true));
    dispatch(resetList(true));
    history.push("/");
  };

  return (
    <MDBNavbar className="z-depth-0">
      <MDBNavbarBrand>
        <Link to="/">
          <img src={Logo} alt="polismall" style={{ height: 30 }}></img>
        </Link>
      </MDBNavbarBrand>

      {isLogin && (
        <div className="user-dropdown" onClick={handleDropdown}>
          <div className="user-icon">
            <MDBIcon icon="user" />
          </div>
          <div className="user-name">
            <p className="mb-0 mx-2 font-weight-bold">
              {user ? user.username : "yourname"}
            </p>
            <MDBIcon icon={dropdown ? "angle-up" : "angle-down"} />
          </div>

          {dropdown && (
            <div className="user-menu z-depth-1">
              <div onClick={handlePassword}>
                <p>Change Password</p>
              </div>
              <div onClick={handleLogout}>
                <p>Logout</p>
              </div>
            </div>
          )}
        </div>
      )}
    </MDBNavbar>
  );
}
