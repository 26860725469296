import React from "react";
import "./Pagination.css";
import ReactPaginate from "react-paginate";
import { MDBIcon } from "mdbreact";

export default function Pagination({ totalPage, page, handlePage }) {
  return (
    <div className="mt-5 d-flex justify-content-end">
      <ReactPaginate
        previousLabel={<MDBIcon icon="chevron-left" />}
        nextLabel={<MDBIcon icon="chevron-right" />}
        breakLabel={"..."}
        pageCount={totalPage}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        forcePage={page}
        onPageChange={handlePage}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
}
