import { useEffect, useState, useRef, Fragment } from "react"
import axiosGet from "../../../helpers/axiosGet"
import { resetList, setExcelForm } from "../../../store/list/actions"
import { useDispatch, useSelector } from "react-redux"
import TableForm from "../../TableForm/TableForm"
import Loading from "../../Loading/Loading"
import TableData from "../../TableData/TableData"
import Pagination from "../../Pagination/Pagination"
import { useCallback } from "react"

export default function ExcelList() {
  const dispatch = useDispatch()
  const scroll = useRef(null)
  const handleScroll = () => {
    scroll.current.scrollIntoView({
      behavior: "smooth",
    })
  }

  const excelForm = useSelector((state) => state.list.excelForm)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [totalPage, setTotalPage] = useState(1)

  const getLists = useCallback(() => {
    setLoading(true)

    axiosGet({
      url: `/upload/page/${excelForm.storedPage}?filename=${excelForm.storedValues.filename}&minUploadDate=${excelForm.storedValues.minUploadDate}&maxUploadDate=${excelForm.storedValues.maxUploadDate}&maxCount=`,
      callback: (res) => {
        setLoading(false)
        setRows(res.data.data)
        setTotalPage(res.data.totalPage)
      },
      errorCallback: (err) => {
        setLoading(false)
        setError(true)
        setErrorMessage(err.message)
      },
    })
  }, [
    excelForm.storedPage,
    excelForm.storedValues.filename,
    excelForm.storedValues.maxUploadDate,
    excelForm.storedValues.minUploadDate,
  ])

  useEffect(() => {
    getLists()

    return () => {
      dispatch(resetList)
    }
  }, [dispatch, getLists])

  const handlePage = (page) => {
    handleScroll()
    dispatch(
      setExcelForm({
        ...excelForm,
        storedPage: page.selected + 1,
      })
    )
  }

  const handleSearch = (data) => {
    handleScroll()
    dispatch(
      setExcelForm({
        ...excelForm,
        storedValues: data,
      })
    )
  }

  const handleReset = () => {
    handleScroll()
    dispatch(
      setExcelForm({
        storedValues: {
          filename: "",
          minUploadDate: "",
          maxUploadDate: "",
        },
        storedPage: 1,
      })
    )
  }

  const forms = [
    {
      label: "File Name",
      name: "filename",
      type: "text",
    },
    {
      label: "Upload Date From",
      name: "minUploadDate",
      type: "date",
    },
    {
      label: "Upload Date To",
      name: "maxUploadDate",
      type: "date",
    },
  ]

  return (
    <div className="excel-list">
      <div className="border rounded bg-white" ref={scroll}>
        <TableForm
          title="excel"
          forms={forms}
          defVals={excelForm.storedValues}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
      </div>

      <div className="mt-5">
        {loading ? (
          <Loading />
        ) : error ? (
          <div className="text-center red-text font-weight-bolder">
            {errorMessage}
          </div>
        ) : rows.length ? (
          <Fragment>
            <div className="border rounded bg-white">
              <TableData rows={rows} title="excel" />
            </div>

            {totalPage > 1 && (
              <Pagination
                totalPage={totalPage}
                page={excelForm.storedPage - 1}
                handlePage={handlePage}
              />
            )}
          </Fragment>
        ) : (
          <div className="text-center font-weight-bold">NO DATA</div>
        )}
      </div>
    </div>
  )
}
