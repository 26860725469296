export const setLogin = (payload) => {
  return {
    type: "SET_LOGIN",
    payload,
  };
};

export const setUser = (payload) => {
  return {
    type: "SET_USER",
    payload,
  };
};

export const resetUser = (payload) => {
  return {
    type: "RESET_USER",
    payload,
  };
};
