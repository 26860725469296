import React from "react";

export default function Heading() {
  return (
    <div className="mb-5">
      <h2 className="font-weight-bold">Polismall Palisade</h2>
      <p className="mb-0">We provide Insurance to let you at ease.</p>
    </div>
  );
}
