import { combineReducers } from "redux";
import file from "./file/reducers";
import user from "./user/reducers";
import notif from "./notif/reducers";
import list from "./list/reducers";

const rootReducers = combineReducers({
  file: file,
  user: user,
  notif: notif,
  list: list,
});

export default rootReducers;
