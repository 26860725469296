import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLogin, setUser } from "../../store/user/actions";
import UserForm from "../../components/UserForm/UserForm";
import axiosPost from "../../helpers/axiosPost";
import constants from "../../constants";

export default function Login() {
  const values = {
    email: "",
    password: "",
  };

  const inputs = [
    {
      name: "email",
      type: "text",
      aria: "Email",
      placeholder: "Email Address",
      icon: "user",
      disabled: false,
    },
    {
      name: "password",
      type: "password",
      aria: "Password",
      placeholder: "Password",
      icon: "lock",
      disabled: false,
    },
  ];
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (data) => {
    setLoading(true);
    setError(false);

    const params = {
      username: data.email,
      password: data.password,
    };

    axiosPost({
      url: `${constants.AUTH_URL}/auth/login`,
      data: params,
      callback: (res) => {
        setLoading(false);
        sessionStorage.setItem("token", res.data.payload.token);
        sessionStorage.setItem("user", res.data.user.userCode);
        dispatch(setUser(res.data.user));
        dispatch(setLogin(true));
      },
      errorCallback: (err) => {
        setLoading(false);
        setError(true);
        setErrorMessage(err.message);
      },
    });
  };
  return (
    <section className="login-page">
      <UserForm
        title="Log in to your account"
        loading={loading}
        values={values}
        inputs={inputs}
        submit={handleSubmit}
        buttonText="Log in"
        error={error}
        errorMessage={errorMessage}
      />
    </section>
  );
}
