import { useState, useEffect, Fragment } from "react"
import { useParams, useHistory } from "react-router-dom"
import Subheading from "../../components/Subheading/Subheading"
import { MDBRow, MDBCol, MDBCard } from "mdbreact"
import "./PalisadeDetail.css"
import Button from "../../components/Button/Button"
import axiosGet from "../../helpers/axiosGet"
import axiosPost from "../../helpers/axiosPost"
import Loading from "../../components/Loading/Loading"
import CurrencyFormat from "../../components/CurrencyFormat"
import EstDepartureDate from "../../components/EstDepartureDate"
import Download from "../../components/Download/Download"
import NotFound from "../NotFound/NotFound"

export default function PalisadeDetail() {
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    const decode = window.atob(id)

    axiosGet({
      url: `/id/${decode}`,
      callback: (res) => {
        setLoading(false)
        setDetail(res.data)
      },
      errorCallback: (err) => {
        setLoading(false)
        history.push("/404")
        console.log("err", err)
      },
    })

    return () => setDetail({})
  }, [history, id])

  const [loading, setLoading] = useState(true)
  const [detail, setDetail] = useState({})
  const [error, setError] = useState(false)

  const [resendLoading, setResendLoading] = useState(false)

  const handleResend = (certificateNumber) => {
    setResendLoading(true)

    const body = {
      certificateNumber: certificateNumber,
    }

    axiosPost({
      url: "/retry",
      data: body,
      callback: (res) => {
        console.log("ok", res)
        setResendLoading(false)
      },
      errorCallback: (err) => {
        console.log("err", err)
        setResendLoading(false)
        setError(true)
      },
    })
  }

  return error ? (
    <NotFound />
  ) : (
    <section className="palisade-detail">
      <div className="text-center">
        <Subheading text="Palisade Detail" divider />
      </div>

      <div className="mt-5">
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <div className="d-flex justify-content-end">
              <Button
                disabled={resendLoading}
                loading={resendLoading}
                onClick={() => handleResend(detail.certificateNumber)}
                text="Resend Certificate"
              />
            </div>

            <div className="mt-4 cards">
              <MDBRow className="create-gap">
                <MDBCol size="12">
                  <MDBCard>
                    <div className="p-4">
                      <MDBRow className="create-small-gap">
                        <MDBCol md="4">
                          <p className="mb-0">Insured (Tertanggung)</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.insured}
                          </p>
                        </MDBCol>
                        <MDBCol md="4">
                          <p className="mb-0">Certificate Number</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.certificateNumber}
                          </p>
                        </MDBCol>
                        <MDBCol md="4">
                          <p className="mb-0">Insurance Type</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.attachment && detail.attachment.length > 0
                              ? detail.attachment[0].insuranceType
                              : "-"}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    <hr style={{ margin: 0 }} />

                    <div className="p-4">
                      <MDBRow className="create-small-gap">
                        <MDBCol md="6">
                          <p className="mb-0">Application Date</p>
                          <p className="mb-0 font-weight-bold">
                            <EstDepartureDate date={detail.applicationDate}/>
                          </p>
                        </MDBCol>
                        <MDBCol md="6">
                          <p className="mb-0">Insurer</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.insurer ? detail.insurer : "-"}
                          </p>
                        </MDBCol>
                        <MDBCol md="6">
                          <p className="mb-0">Route</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.route}
                          </p>
                        </MDBCol>
                        <MDBCol md="6">
                          <p className="mb-0">Deductibles</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.deductibles}
                          </p>
                        </MDBCol>
                        <MDBCol md="6">
                          <p className="mb-0">Estimated Departure Date</p>
                          <p className="mb-0 font-weight-bold">
                            <EstDepartureDate date={detail.estDateDeparture}/>
                          </p>
                        </MDBCol>
                        <MDBCol md="6">
                          <p className="mb-0">Conditions</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.conditions && (
                              <Conditions value={detail.conditions} />
                            )}
                          </p>
                        </MDBCol>
                        <MDBCol md="6">
                          <p className="mb-0">Vessel</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.vessel}
                          </p>
                        </MDBCol>
                        <MDBCol md="6">
                          <MDBRow end>
                            <MDBCol md="6">
                              <p className="mb-0">Placing Slip Number</p>
                              <p className="mb-0 font-weight-bold">
                                {detail.placingSlipNumber}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MDBCard>
                </MDBCol>
                <MDBCol size="12">
                  <MDBCard>
                    <div className="p-4">
                      <MDBRow className="create-small-gap">
                        <MDBCol md="6">
                          <p className="mb-0">Total Sum Insured</p>

                          <div className="d-flex align-items-center">
                            <p className="mb-0">({detail.currencyCode})</p>
                            <p
                              className="mb-0 font-weight-bold"
                              style={{ fontSize: 20 }}
                            >
                              {CurrencyFormat(detail.sumInsured)}
                            </p>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <p className="mb-0">Net Premium</p>

                          <div className="d-flex align-items-center">
                            <p className="mb-0">({detail.currencyCode})</p>
                            <p
                              className="mb-0 font-weight-bold"
                              style={{ fontSize: 20 }}
                            >
                              {CurrencyFormat(detail.netPremium)}
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    <hr style={{ margin: 0 }} />

                    <div className="p-4">
                      <MDBRow className="create-small-gap">
                        <MDBCol md="12">
                          <div className="middle-container">
                            <MDBRow className="create-small-gap">
                              <MDBCol size="3">
                                <p className="mb-0">Container Number</p>
                              </MDBCol>
                              <MDBCol size="3">
                                <p className="mb-0">Interest Insured</p>
                              </MDBCol>
                              <MDBCol size="3">
                                <p className="mb-0">Sum Insured</p>
                              </MDBCol>
                              <MDBCol size="3">
                                <p className="mb-0">Premium</p>
                              </MDBCol>

                              {detail.attachment &&
                                detail.attachment.map((x, i) => (
                                  <Fragment key={`containers${i}`}>
                                    <MDBCol size="3">
                                      <p className="mb-0 font-weight-bold">
                                        {x.noContainer ? x.noContainer : "-"}
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="3">
                                      <p className="mb-0 font-weight-bold">
                                        {x.interesetInsured
                                          ? x.interesetInsured
                                          : "-"}
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="3">
                                      <p className="mb-0 font-weight-bold">
                                        {CurrencyFormat(x.sumInsured)}
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="3">
                                      <p className="mb-0 font-weight-bold">
                                        {CurrencyFormat(x.premium)}
                                      </p>
                                    </MDBCol>
                                  </Fragment>
                                ))}
                            </MDBRow>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    <hr style={{ margin: 0 }} />

                    <div className="p-4">
                      <MDBRow className="create-small-gap">
                        <MDBCol md="6" lg="8">
                          <p className="mb-0">Customer Email</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.emailTo ? detail.emailTo : "-"}
                          </p>
                        </MDBCol>
                        <MDBCol md="6" lg="4">
                          <p className="mb-0">Meratus Email</p>
                          <p className="mb-0 font-weight-bold">
                            {detail.emailCc ? detail.emailCc : "-"}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MDBCard>
                </MDBCol>

                <MDBCol size="12">
                  <Download
                    title="palisade"
                    text="Download Certificate"
                    id={detail.id}
                    fileName={detail.sourceFileName}
                  />
                </MDBCol>
              </MDBRow>
            </div>

            <div className="mt-5 d-flex">
              <Button
                back
                secondary
                text="Back"
                onClick={() => history.goBack()}
              />
            </div>
          </Fragment>
        )}
      </div>
    </section>
  )
}

function Conditions({ value }) {
  const values = value.split("-").filter((v) => v)

  return (
    <Fragment>
      {values.map((v, i) => (
        <Fragment key={`conditions${i}`}>
          <span>- {v}</span>
          {i < values.length - 1 && <br />}
        </Fragment>
      ))}
    </Fragment>
  )
}
