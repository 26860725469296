import React from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import Button from "../Button/Button";

export default function Modal({
  id,
  isOpen,
  toggle,
  header,
  body,
  handleButton,
  buttonText,
  secondaryButton,
}) {
  return (
    <MDBModal isOpen={isOpen} toggle={toggle} centered fade={false} id={id}>
      <MDBModalHeader toggle={toggle}>{header}</MDBModalHeader>
      <MDBModalBody>{body}</MDBModalBody>
      <MDBModalFooter>
        <Button
          secondary={secondaryButton}
          text={buttonText}
          onClick={handleButton}
        ></Button>
      </MDBModalFooter>
    </MDBModal>
  );
}
