import { useEffect, useState, Fragment, useRef, useCallback } from "react"
import { useParams, useHistory } from "react-router-dom"
import Loading from "../../components/Loading/Loading"
import Subheading from "../../components/Subheading/Subheading"
import axiosGet from "../../helpers/axiosGet"
import { useDispatch, useSelector } from "react-redux"
import { resetList, setExcelDetailForm } from "../../store/list/actions"
import "./ExcelDetail.css"
import moment from "moment"
import TableForm from "../../components/TableForm/TableForm"
import TableData from "../../components/TableData/TableData"
import Pagination from "../../components/Pagination/Pagination"
import Button from "../../components/Button/Button"
import Download from "../../components/Download/Download"

export default function ExcelDetail() {
  const { id } = useParams()
  const decode = window.atob(id)

  const history = useHistory()
  const dispatch = useDispatch()
  const scroll = useRef(null)
  const handleScroll = () => {
    scroll.current.scrollIntoView({
      behavior: "smooth",
    })
  }

  const excelDetailForm = useSelector((state) => state.list.excelDetailForm)

  const getLists = useCallback(() => {
    setError(false)
    setTableLoading(true)

    axiosGet({
      url: `/page/${excelDetailForm.storedPage}?certificateNumber=${excelDetailForm.storedValues.certificateNumber}&placingSlipNumber=&insured=${excelDetailForm.storedValues.insured}&minApplicationDate=${excelDetailForm.storedValues.minApplicationDate}&maxApplicationDate=${excelDetailForm.storedValues.maxApplicationDate}&status=&uploadId=${decode}&maxCount=`,
      callback: (res) => {
        setTableLoading(false)
        setRows(res.data.data)
        setTotalPage(res.data.totalPage)
      },
      errorCallback: (err) => {
        console.log("err", err)
        setTableLoading(false)
        setError(true)
        setErrorMessage(err.message)
      },
    })
  }, [
    decode,
    excelDetailForm.storedPage,
    excelDetailForm.storedValues.certificateNumber,
    excelDetailForm.storedValues.insured,
    excelDetailForm.storedValues.maxApplicationDate,
    excelDetailForm.storedValues.minApplicationDate,
  ])

  useEffect(() => {
    axiosGet({
      url: `/upload/id/${decode}`,
      callback: (res) => {
        setLoading(false)
        setDetail(res.data)
      },
      errorCallback: (err) => {
        setLoading(false)
        history.push("/404")
        console.log("err", err)
      },
    })

    getLists()

    return () => dispatch(resetList)
  }, [decode, dispatch, getLists, history])

  const [loading, setLoading] = useState(true)
  const [detail, setDetail] = useState({})

  const [rows, setRows] = useState([])
  const [tableLoading, setTableLoading] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [totalPage, setTotalPage] = useState(1)

  const handlePage = (page) => {
    handleScroll()
    dispatch(
      setExcelDetailForm({
        ...excelDetailForm,
        storedPage: page.selected + 1,
      })
    )
  }

  const handleSearch = (data) => {
    handleScroll()
    dispatch(
      setExcelDetailForm({
        storedValues: data,
        storedPage: 1,
      })
    )
  }

  const handleReset = () => {
    handleScroll()
    dispatch(
      setExcelDetailForm({
        storedValues: {
          certificateNumber: "",
          insured: "",
          minApplicationDate: "",
          maxApplicationDate: "",
        },
        storedPage: 1,
      })
    )
  }

  const forms = [
    {
      label: "Certificate Number",
      name: "certificateNumber",
      type: "text",
    },
    {
      label: "Insured",
      name: "insured",
      type: "text",
    },
    {
      label: "App Date From",
      name: "minApplicationDate",
      type: "date",
    },
    {
      label: "App Date To",
      name: "maxApplicationDate",
      type: "date",
    },
  ]

  return (
    <section className="excel-detail">
      <div className="text-center">
        <Subheading text="Excel Detail" divider />
      </div>

      <div className="mt-5">
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <div className="detail-area">
              <p className="mb-0">File Name</p>
              <p className="font-weight-bold">{detail.fileOriginalName}</p>

              <p className="mb-0">Upload Date</p>
              <p className="mb-0 font-weight-bold">
                {moment(detail.uploadDate).format("DD/MM/YYYY")}
              </p>
            </div>

            <div className="border rounded bg-white mt-5" ref={scroll}>
              <TableForm
                title="detail"
                forms={forms}
                defVals={excelDetailForm.storedValues}
                handleSearch={handleSearch}
                handleReset={handleReset}
              />
            </div>

            <div className="mt-5">
              {tableLoading ? (
                <Loading />
              ) : error ? (
                <div className="text-center red-text font-weight-bolder">
                  {errorMessage}
                </div>
              ) : rows.length ? (
                <Fragment>
                  <div className="border rounded bg-white">
                    <TableData rows={rows} title="detail" />
                  </div>

                  {totalPage > 1 && (
                    <Pagination
                      totalPage={totalPage}
                      page={excelDetailForm.storedPage - 1}
                      handlePage={handlePage}
                    />
                  )}
                </Fragment>
              ) : (
                <div className="text-center font-weight-bold">NO DATA</div>
              )}
            </div>

            <div className="mt-5">
              <Download
                title="excel"
                text="Download Excel File"
                id={detail.id}
                fileName={detail.fileOriginalName}
              />
            </div>

            <div className="mt-5 d-flex">
              <Button
                back
                secondary
                text="Back"
                onClick={() => history.goBack()}
              />
            </div>
          </Fragment>
        )}
      </div>
    </section>
  )
}
