import React from "react";
import { MDBNotification } from "mdbreact";
import { useDispatch } from "react-redux";
import {
  setIsFileUploaded,
  setIsPasswordChanged,
} from "../../store/notif/actions";

export default function Notification({ title, message }) {
  const dispatch = useDispatch();

  const handleCloseNotification = (title) => {
    dispatch(
      title === "file" ? setIsFileUploaded(false) : setIsPasswordChanged(false)
    );
  };
  return (
    <div onClick={() => handleCloseNotification(title)}>
      <MDBNotification
        show
        fade
        iconClassName="green-text"
        title="Success"
        message={message}
        text="just now"
        style={{
          position: "fixed",
          top: "10px",
          right: "10px",
          zIndex: 9999,
          width: "15rem",
        }}
      />
    </div>
  );
}
