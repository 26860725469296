import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap-css-only/css/bootstrap.min.css"
import "mdbreact/dist/css/mdb.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import store from "./store"
import { resetUser } from "./store/user/actions"
import axios from "axios"
import constants from "../src/constants"

axios.defaults.baseURL = constants.DEFAULT_URL

axios.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem("token")
    const user = sessionStorage.getItem("user")
    if (token && user) {
      config.headers["X-Authorization"] = `Bearer ${token}`
      config.headers["X-User"] = user
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    if (response.data.statusCode === constants.HTTP_STATUS_UNAUTHORIZED) {
      sessionStorage.removeItem("token")
      sessionStorage.removeItem("user")
      store.dispatch(resetUser())
    }

    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
