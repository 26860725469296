import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import Background from "../../img/Group871.png";
import Ballons from "../../img/ballons.svg";
import "./NotFound.css";

export default function NotFound() {
  const history = useHistory();

  return (
    <section className="notfound-page">
      <img src={Background} alt="background" className="notfound-bg" />
      <img src={Ballons} alt="ballons" className="notfound-ballons" />

      <h1>404</h1>
      <h2>Halaman Tidak Ditemukan</h2>
      <p>
        Alamat yang anda masukkan mungkin salah atau anda tidak mempunyai hak
        akses kepada halaman tersebut.
      </p>

      <div className="d-flex justify-content-center">
        <Button
          text="HOME"
          onClick={() => {
            history.push("/");
            window.scrollTo(0, 0);
          }}
        />
      </div>
    </section>
  );
}
