import React from "react";
import "./Subheading.css";

export default function Subheading({ text, divider }) {
  return (
    <div className="subheading">
      {text}

      {divider && <hr />}
    </div>
  );
}
