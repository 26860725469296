export const setFile = (payload) => {
  return {
    type: "SET_FILE",
    payload,
  };
};

export const setRows = (payload) => {
  return {
    type: "SET_ROWS",
    payload,
  };
};

export const resetFile = (payload) => {
  return {
    type: "RESET_FILE",
    payload,
  };
};
