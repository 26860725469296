import React, { useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBProgress,
  MDBIcon,
} from "mdbreact"
import "./Home.css"
import Excel from "../../img/excel.png"
import Swimming from "../../img/swimming.svg"
import Button from "../../components/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { setFile, setRows } from "../../store/file/actions"
import { setActive } from "../../store/list/actions"
import * as XLSX from "xlsx"
import Subheading from "../../components/Subheading/Subheading"
import PalisadeList from "../../components/Home/PalisadeList/PalisadeList"
import ExcelList from "../../components/Home/ExcelList/ExcelList"
import * as moment from "moment"

export default function Home() {
  const dispatch = useDispatch()
  const active = useSelector((state) => state.list.active)

  const links = [
    {
      value: "upload",
      title: "Palisade Upload",
    },
    {
      value: "palisade",
      title: "Palisade List",
    },
    {
      value: "excel",
      title: "Excel List",
    },
  ]

  return (
    <section className="home-page">
      <div className="links">
        {links.map((link) => (
          <div
            onClick={() => dispatch(setActive(link.value))}
            style={{
              cursor: active !== link.value ? "pointer" : "",
              textAlign: "center",
            }}
            key={link.title}
          >
            <Subheading text={link.title} divider={active === link.value} />
          </div>
        ))}
      </div>

      <div className="mt-5">
        <SwitchLink active={active} />
      </div>
    </section>
  )
}

function SwitchLink({ active }) {
  switch (active) {
    case "upload":
      return <Upload />
    case "palisade":
      return <PalisadeList />
    case "excel":
      return <ExcelList />

    default:
      return <Upload />
  }
}

function Upload() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [selectedFile, setSelectedFile] = useState(null)
  const [excel, setExcel] = useState([])
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const hiddenFileInput = useRef(null)

  const handleFileInput = () => {
    hiddenFileInput.current.click()
  }

  const handleSelectedFiles = (event) => {
    const file = event.target.files[0]

    if (file) {
      setSelectedFile(file)

      new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsArrayBuffer(file)

        fileReader.onload = (e) => {
          const arrayBuffer = e.target.result
          const wb = XLSX.read(arrayBuffer, {
            type: "buffer",
            cellDates: true,
          })
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          const data = XLSX.utils.sheet_to_json(ws, {
            defval: null,
          })
          resolve(data)
        }

        fileReader.onerror = (err) => {
          reject(err)
        }
      }).then((d) => {
        setExcel(d)
      })
    }
  }

  const checkMandatoryIndex = (value, keys, mandatoryIndex) => {
    let isValid = -1
    for (const num of mandatoryIndex) {
      if (
        value[keys[num]] === undefined ||
        value[keys[num]] == null ||
        `${value[keys[num]]}`.toUpperCase() === "TBA"
      ) {
        isValid = num
        break
      }
    }
    return isValid
  }

  const handleUpload = () => {
    setLoading(true)
    setError(false)
    dispatch(setFile(selectedFile))

    let index = 1
    new Promise((resolve, reject) => {
      try {
        const data = []
        let temp = null
        const mandatoryFullIndex = [1, 3, 4, 5, 6, 9]
        const mandatoryPartialIndex = [4, 5, 6]
        for (const x of excel) {
          index++
          const keys = Object.keys(x)
          if (x[keys[0]] != null && `${x[keys[0]]}`.toUpperCase() !== "TBA") {
            const isValid = checkMandatoryIndex(x, keys, mandatoryFullIndex)
            if (isValid > -1) throw new Error(isValid)
            if (temp != null) {
              data.push(temp)
            }
            if (!checkInsuranceType(x[keys[3]])) throw new Error(3);
            temp = {
              insured: x[keys[0]],
              route: x[keys[1]],
              estDepartureDate: moment(x[keys[2]]).add(1, "hours"),
              insuranceType: x[keys[3]],
              attachment: [
                {
                  containerNo: x[keys[4]],
                  interestInsured: x[keys[5]],
                  sumInsured: x[keys[6]],
                },
              ],
              customerEmail: x[keys[7]],
              meratusEmail: x[keys[8]],
              vessel: x[keys[9]],
            }
          } else {
            if (temp && temp.attachment && temp.attachment.length > 0) {
              const isValid = checkMandatoryIndex(
                x,
                keys,
                mandatoryPartialIndex
              )
              if (isValid > -1) throw new Error(isValid)
              temp.attachment.push({
                containerNo: x[keys[4]],
                interestInsured: x[keys[5]],
                sumInsured: x[keys[6]],
              })
            } else {
              throw new Error(0)
            }
          }
        }
        data.push(temp)
        resolve(data)
      } catch (err) {
        reject(err.message)
      }
    })
      .then((rows) => {
        dispatch(setRows(rows))
        setTimeout(() => {
          setProgress(Math.floor(Math.random() * 101))
        }, 250)
        setTimeout(() => {
          setProgress(100)
        }, 500)
        setTimeout(() => {
          setLoading(false)
          history.push("/summary")
          window.scrollTo(0, 0)
        }, 750)
      })
      .catch((err) => {
        setLoading(false)
        setSelectedFile(null)
        setError(true)
        setErrorMessage(
          <p className="text-danger font-small mt-2 mb-0">
            *Baris {index} kolom <b>{columnName(err)}</b> harus diisi
          </p>
        )
      })
  }

  const checkInsuranceType = (insuranceType) => {
    switch (insuranceType.trim().toUpperCase()) {
      case "ICC A":
      case "ICC C":
      case "FLB A":
      case "FLB C":
        return true;
      default:
        return false;
    }
  }

  const columnName = (index) => {
    switch (`${index}`) {
      case "0":
        return "Tertanggung"
      case "1":
        return "Route"
      case "3":
        return "Tipe Asuransi"
      case "4":
        return "Nomor Container"
      case "5":
        return "Jenis Barang"
      case "6":
        return "Nilai Barang"
      default:
        return index
    }
  }

  return (
    <MDBRow className="create-gap">
      <MDBCol md="8">
        <MDBRow className="create-gap">
          <MDBCol size="12">
            <MDBCard className="dropzone-area">
              <div className="inner-dropzone">
                <img src={Excel} alt="microsoft excel" />
                <h4 className="font-weight-bold my-4">Drop your .Xlsx here</h4>
                <div className="buttons">
                  <input
                    id="insured-file-input"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleSelectedFiles}
                    ref={hiddenFileInput}
                  />

                  <Button
                    small
                    secondary
                    text="Choose File"
                    disabled={loading}
                    onClick={handleFileInput}
                  />
                  <Button
                    small
                    text="Upload File"
                    icon="cloud-upload-alt"
                    disabled={loading || !selectedFile}
                    onClick={handleUpload}
                  />
                </div>

                <div>{error && errorMessage}</div>
              </div>
            </MDBCard>
          </MDBCol>

          <MDBCol size="12">
            <div className="progress-area">
              <div className="swimming"></div>
              <MDBRow>
                <MDBCol size="4" middle>
                  <img src={Swimming} alt="man swimming" />
                </MDBCol>
                <MDBCol size="8" middle>
                  <MDBRow>
                    <MDBCol size="12">
                      <MDBContainer>
                        <MDBRow between>
                          <p className="mb-0 font-weight-bold">
                            {selectedFile
                              ? selectedFile.name
                              : "file_name.xlsx"}
                          </p>
                          <p className="mb-0 grey-text">{progress}%</p>
                        </MDBRow>
                      </MDBContainer>
                    </MDBCol>
                    <MDBCol size="12">
                      <MDBProgress
                        value={progress}
                        height="0.5rem"
                        className="mt-2"
                        color="success"
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </div>

            <p className="mb-0 mt-2 font-italic font-small grey-text">
              Important Note: Maximal Upload 20 insured
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol md="4">
        <p className="font-weight-bold">Download Template</p>
        <MDBCard className="template-area">
          <MDBRow>
            <MDBCol size="2" middle>
              <img src={Excel} alt="microsoft excel" />
            </MDBCol>
            <MDBCol size="7" middle>
              <p className="mb-0">Palisade Template</p>
            </MDBCol>
            <MDBCol size="3" middle>
              <MDBContainer>
                <MDBRow end>
                  <div className="button">
                    <a href="/paliside-template.xlsx">
                      <MDBIcon
                        icon="arrow-down"
                        className="white-text"
                        size="1x"
                      />
                    </a>
                  </div>
                </MDBRow>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  )
}
