import React from "react";
import { MDBCard, MDBRow, MDBCol } from "mdbreact";
import Button from "../Button/Button";
import Oval from "../../img/oval.svg";
import Oval2 from "../../img/oval2.svg";
import "./UserForm.css";
import { useForm } from "react-hook-form";

export default function UserForm({
  title,
  loading,
  inputs,
  values,
  submit,
  buttonText,
  error,
  errorMessage,
}) {
  const { register, handleSubmit } = useForm({ defaultValues: values });
  const onSubmit = (data) => submit(data);

  return (
    <MDBCard className="form-card">
      <img src={Oval} alt="oval" className="oval" />
      <img src={Oval2} alt="oval" className="oval2" />

      <h4 className="font-weight-bold text-center">{title}</h4>

      <form onSubmit={handleSubmit(onSubmit)} className="grey-text mt-5">
        <MDBRow className="create-small-gap">
          {inputs.map((x, i) => (
            <MDBCol size="12" key={`form${i}`}>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon">
                    <i className={`fa fa-${x.icon} prefix`}></i>
                  </span>
                </div>
                <input
                  name={x.name}
                  type={x.type}
                  className="form-control"
                  placeholder={x.placeholder}
                  aria-label={x.aria}
                  aria-describedby="basic-addon"
                  disabled={x.disabled}
                  {...register(x.name, { required: true })}
                />
              </div>
            </MDBCol>
          ))}
        </MDBRow>

        {error && (
          <p className="text-danger font-small mt-4">*{errorMessage}</p>
        )}

        <div className="mt-4">
          <Button
            text={buttonText}
            type="submit"
            full
            disabled={loading}
            loading={loading}
          />
        </div>
      </form>
    </MDBCard>
  );
}
