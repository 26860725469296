const initialState = {
  active: "upload",
  palisadeForm: {
    storedValues: {
      certificateNumber: "",
      insured: "",
      minApplicationDate: "",
      maxApplicationDate: "",
    },
    storedPage: 1,
  },
  excelForm: {
    storedValues: {
      filename: "",
      minUploadDate: "",
      maxUploadDate: "",
    },
    storedPage: 1,
  },
  excelDetailForm: {
    storedValues: {
      certificateNumber: "",
      insured: "",
      minApplicationDate: "",
      maxApplicationDate: "",
    },
    storedPage: 1,
  },
}

const listReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE":
      return {
        ...state,
        active: action.payload,
      }
    case "SET_PALISADE_FORM":
      return {
        ...state,
        palisadeForm: action.payload,
      }
    case "SET_EXCEL_FORM":
      return {
        ...state,
        excelForm: action.payload,
      }
    case "SET_EXCEL_DETAIL_FORM":
      return {
        ...state,
        excelDetailForm: action.payload,
      }

    case "RESET_LIST":
      return (state = initialState)

    default:
      return state
  }
}

export default listReducer
