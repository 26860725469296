const initialState = {
  isFileUploaded: false,
  isPasswordChanged: false,
};

const notifReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IS_FILE_UPLOADED":
      return {
        ...state,
        isFileUploaded: action.payload,
      };
    case "SET_IS_PASSWORD_CHANGED":
      return {
        ...state,
        isPasswordChanged: action.payload,
      };
    default:
      return state;
  }
};

export default notifReducer;
