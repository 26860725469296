import { useState, Fragment } from "react"
import { useHistory } from "react-router-dom"
import "./Summary.css"
import { MDBCard, MDBRow, MDBCol, MDBContainer } from "mdbreact"
import { useSelector, useDispatch } from "react-redux"
import { setIsFileUploaded } from "../../store/notif/actions"
import { resetFile } from "../../store/file/actions"
import { resetList } from "../../store/list/actions"
import Button from "../../components/Button/Button"
import moment from "moment"
import CurrencyFormat from "../../components/CurrencyFormat"
import EstDepartureDate from "../../components/EstDepartureDate"
import axiosPost from "../../helpers/axiosPost"
import Loading from "../../components/Loading/Loading"
import Subheading from "../../components/Subheading/Subheading"
import Modal from "../../components/Modal/Modal"

export default function Summary() {
  const { file, rows } = useSelector((state) => state.file)

  const dispatch = useDispatch()
  const history = useHistory()

  const [agreement, setAgreement] = useState(false)
  const [agreementError, setAgreementError] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [termModal, setTermModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [invalidTSI, setInvalidTSI] = useState(false)
  const [invalidTSIMessage, setInvalidTSIMessage] = useState("")

  const totalSumInsured = (value) => {
    const containers = value.attachment

    const sumInsured = containers.reduce(
      (total, num) => total + num.sumInsured,
      0
    )
    return CurrencyFormat(sumInsured)
  }

  const handleErrorModal = () => {
    setError(!error)
  }

  const handleTermModal = () => {
    setTermModal(!termModal)
  }

  const handleTerm = () => {
    setAgreement(true)
    setAgreementError(false)
    setTermModal(false)
  }

  const handleAgreement = (e) => {
    setAgreement(e.target.checked)
    setAgreementError(e.target.checked ? false : true)
  }

  const handleSubmit = () => {
    if (!agreement) {
      setAgreementError(true)
    } else {
      const isTotalSumInsuredValid = rows
        .map((row) => {
          const sumInsured = row.attachment.reduce(
            (total, num) => total + num.sumInsured,
            0
          )
          return sumInsured
        })
        .every((row, i) => {
          setInvalidTSIMessage(
            <p className="text-danger font-small mt-2">
              *TSI di insured <b>ke-{i + 1}</b> kurang dari IDR 10.000.000
            </p>
          )

          return row >= 10000000
        })

      if (!isTotalSumInsuredValid) {
        setInvalidTSI(true)
      } else {
        window.scrollTo(0, 0)

        setInvalidTSI(false)
        setError(false)
        setLoading(true)

        const fd = new FormData()
        fd.append("file", file)
        axiosPost({
          url: `/upload`,
          data: fd,
          callback: () => {
            setLoading(false)
            dispatch(resetFile(true))
            dispatch(resetList(true))
            dispatch(setIsFileUploaded(true))
            history.push("/")
          },
          errorCallback: (err) => {
            setLoading(false)
            if (err.statusCode === 400) {
              setError(true)
              setErrorMessage(
                "File is missing because you have refreshed this page, please go back and reupload the file."
              )
            } else {
              setError(true)
              setErrorMessage(err.message)
              console.log("err", err)
            }
          },
        })
      }
    }
  }

  return (
    <section className="summary-page">
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="text-center">
            <Subheading text="Summary" divider />
          </div>

          <div className="cards mt-5">
            <MDBRow className="create-gap">
              {rows.map((row, i) => (
                <MDBCol size="12" key={`insured${i}`}>
                  <MDBCard>
                    <div className="p-4">
                      <MDBRow className="create-small-gap">
                        <MDBCol md="4">
                          <p className="mb-0">Insured (Tertanggung)</p>
                          <p className="mb-0 font-weight-bold">{row.insured}</p>
                        </MDBCol>
                        <MDBCol md="4">
                          <p className="mb-0">Route</p>
                          <p className="mb-0 font-weight-bold">{row.route}</p>
                        </MDBCol>
                        <MDBCol md="4">
                          <p className="mb-0">Vessel</p>
                          <p className="mb-0 font-weight-bold">{row.vessel}</p>
                        </MDBCol>
                        <MDBCol md="4">
                          <p className="mb-0">Insurance Type</p>
                          <p className="mb-0 font-weight-bold">
                            {row.insuranceType}
                          </p>
                        </MDBCol>
                        <MDBCol md="4">
                          <p className="mb-0">Application Date</p>
                          <p className="mb-0 font-weight-bold">
                            {moment().format("DD MMMM YYYY")}
                          </p>
                        </MDBCol>
                        <MDBCol md="8">
                          <p className="mb-0">Estimated Departure Date</p>
                          <p className="mb-0 font-weight-bold">
                            <EstDepartureDate date={row.estDepartureDate} />
                          </p>
                        </MDBCol>

                        <MDBCol md="4">
                          <p className="mb-0">Total Sum Insured</p>

                          <div className="d-flex align-items-center">
                            <p className="mb-0">(IDR)</p>
                            <p
                              className="mb-0 font-weight-bold"
                              style={{ fontSize: 20 }}
                            >
                              {totalSumInsured(row)}
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    <hr style={{ margin: 0 }} />

                    <div className="p-4">
                      <MDBRow className="create-small-gap">
                        <MDBCol md="12">
                          <div className="middle-container">
                            <MDBRow className="create-small-gap">
                              <MDBCol size="4">
                                <p className="mb-0">Container Number</p>
                              </MDBCol>
                              <MDBCol size="4">
                                <p className="mb-0">Interest Insured</p>
                              </MDBCol>
                              <MDBCol size="4">
                                <p className="mb-0">Sum Insured</p>
                              </MDBCol>

                              <Containers values={row.attachment} />
                            </MDBRow>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>

                    <hr style={{ margin: 0 }} />

                    <div className="p-4">
                      <MDBRow className="create-small-gap">
                        <MDBCol md="6" lg="8">
                          <p className="mb-0">Customer Email</p>
                          <p className="mb-0 font-weight-bold">
                            {row.customerEmail ? row.customerEmail : "-"}
                          </p>
                        </MDBCol>
                        <MDBCol md="6" lg="4">
                          <p className="mb-0">Meratus Email</p>
                          <p className="mb-0 font-weight-bold">
                            {row.meratusEmail ? (
                              <FormatEmail email={row.meratusEmail} />
                            ) : (
                              "-"
                            )}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MDBCard>
                </MDBCol>
              ))}
            </MDBRow>
          </div>

          <div style={{ marginTop: "6rem" }}>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="agreementCheckbox"
                name="agreement"
                checked={agreement}
                onChange={handleAgreement}
              />
              <label
                className="custom-control-label"
                htmlFor="agreementCheckbox"
              >
                I agree to this{" "}
                <a
                  href="#termModal"
                  onClick={(e) => {
                    e.preventDefault()
                    handleTermModal()
                  }}
                >
                  Microsite Term of Service and Conditions
                </a>
              </label>
            </div>

            {agreementError && (
              <p className="text-danger font-small mt-2">*You must agree</p>
            )}

            {invalidTSI && invalidTSIMessage}
          </div>

          <div className="mt-5">
            <MDBContainer>
              <MDBRow between>
                <Button
                  back
                  secondary
                  text="Back"
                  onClick={() => {
                    window.scrollTo(0, 0)
                    history.push("/")
                  }}
                ></Button>

                <Button text="Submit" onClick={handleSubmit}></Button>
              </MDBRow>
            </MDBContainer>
          </div>

          <Modal
            id="termModal"
            isOpen={termModal}
            toggle={handleTermModal}
            header={`Term & Conditions`}
            body={<TermModalBody />}
            handleButton={handleTerm}
            buttonText="I Agree"
          />

          <Modal
            id="errorModal"
            isOpen={error}
            toggle={handleErrorModal}
            header={`Error`}
            body={errorMessage}
            handleButton={() => history.push("/")}
            buttonText="Go Back"
            secondaryButton
          />
        </Fragment>
      )}
    </section>
  )
}

function FormatEmail({ email }) {
  const emails = email.split(" ")

  return (
    <span>
      {emails.map((email, i) => (
        <span key={`email${i}`}>
          {email}
          {i < emails.length && <br />}
        </span>
      ))}
    </span>
  )
}

function Containers({ values }) {
  return values.map((value, i) => (
    <Fragment key={`containers${i}`}>
      <MDBCol size="4">
        <p className="mb-0 font-weight-bold">{value.containerNo}</p>
      </MDBCol>
      <MDBCol size="4">
        <p className="mb-0 font-weight-bold">{value.interestInsured}</p>
      </MDBCol>
      <MDBCol size="4">
        <p className="mb-0 font-weight-bold">
          {CurrencyFormat(value.sumInsured)}
        </p>
      </MDBCol>
    </Fragment>
  ))
}

function TermModalBody() {
  return (
    <Fragment>
      This microsite is developed and powered by PT. Pialang Asuransi Provis
      Mitra Sinergi as a form of collaboration and cooperation regarding Marine
      Cargo Insurance Marketing of PT. Asuransi Harta Aman Pratama, Tbk between
      PT. Meratus Line and PT. Pialang Asuransi Provis Mitra Sinergi.
      <br />
      <br />
      The information contained in this microsite is for general information
      purpose only, and the data information inputted in this microsite is
      solely used for purchase of such product purpose.
      <br />
      <br />
      As an insurance broker, we make representations of our clients however we
      make no promises and/or warranties of any kind herein, express or implied,
      about the completeness, accuracy, reliability, suitability or availability
      with respect to the data information inputted in microsite or the
      information, products, services on this microsite for any purpose.
      <br />
      <br />
      Any reliance you place on such information in this microsite is therefore
      strictly at your own risk. In no event will PT. Pialang Asuransi Provis
      Mitra Sinergi be liable for any loss or damage including without
      limitation, indirect or consequential loss or damage, or any loss or
      damage whatsoever arising from fail in performing PT. Asuransi Harta Aman
      Pratama, Tbk obligation and/or commit the negligence and/or violates the
      provisions in accordance with the Policy, arising out of, or in connection
      with, the Marine Cargo Product itself, as PT. Asuransi Harta Aman Pratama,
      Tbk is the product owner.
    </Fragment>
  )
}
